import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import { useEffect } from "react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        alt="logo"
        src="https://indigometricsmediastore214507-dev.s3.us-east-2.amazonaws.com/public/Logo.png"
        padding={tokens.space.medium}
      />
    </Flex>
  );
}
