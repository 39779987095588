import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import MediaDataModel from "../models/MediaDataModel";
import VideoDisplay from "./VideoDisplay";

const RenderButton = (props: GridRenderCellParams) => {
  return <VideoDisplay videoUrl={`${props.value}#t=1`}></VideoDisplay>;
};

const columns: GridColDef[] = [
  {
    field: "videoUrl",
    headerName: "Video",
    renderCell: RenderButton,
    width: 125,
    align: "center",
  },
  {
    field: "title",
    headerName: "Title",
    width: 900,
    editable: false,
    sortable: false,
  },
  {
    field: "mediaPrimaryKeyId",
    headerName: "Media Id",
    width: 100,
    editable: false,
  },
  {
    field: "impressions",
    headerName: "Video Impressions",
    width: 160,
    editable: false,
  },
  {
    field: "relativeImpact",
    headerName: "Relative Impact (%)",
    width: 175,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.value && params.value != 0) {
        return Math.floor(params.value * 100);
      } else {
        return "Not Available";
      }
    },
    sortComparator: (v1, v2) => {
      if (typeof v1 == "string") return -1;
      if (typeof v1 == "number" && typeof v2 == "string") return 1;
      return v1 - v2;
    },
  },
];

interface TabPanelProps {
  data: MediaDataModel[];
  loading: boolean;
}

export default function DataDisplay(props: TabPanelProps) {
  return (
    <Box sx={{ height: "78vh", width: "100%" }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        loading={props.loading}
        rowsPerPageOptions={[5]}
        autoPageSize={true}
        rowHeight={50}
        initialState={{
          sorting: {
            sortModel: [
              { field: "relativeImpact", sort: "desc" },
              { field: "mediaPrimaryKeyId", sort: "asc" },
            ],
          },
        }}
      />
    </Box>
  );
}
