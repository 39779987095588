import { Box, Modal } from "@mui/material";
import React from "react";

interface VideoDisplayProps {
  videoUrl: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  height: "90%",
};

export default function VideoDisplay(props: VideoDisplayProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <video
        style={{ height: "90%", padding: "2.5%", justifySelf: "center" }}
        onClick={handleOpen}
      >
        <source src={props.videoUrl} />
      </video>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <video height={"100%"} controls>
            <source src={props.videoUrl} />
          </video>
        </Box>
      </Modal>
    </>
  );
}
