import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Auth } from "aws-amplify";
import { useAuthenticator, useTheme } from "@aws-amplify/ui-react";

export default function ButtonAppBar() {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { tokens } = useTheme();

  return (
    <Box sx={{ flexGrow: 10 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            justifyContent="left"
            variant="h6"
            component="div"
            sx={{ flexGrow: 8 }}
          >
            Indigometrics Media Data
          </Typography>
          <Button color="inherit" sx={{ flexGrow: 1 }} onClick={signOut}>
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
