import { useAuthenticator } from "@aws-amplify/ui-react";
import ButtonAppBar from "./AppBar";
import TabNavigation from "./TabPanel";

export default function Home() {
  return (
    <div>
      <ButtonAppBar />
      <TabNavigation />
    </div>
  );
}
