import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataDisplay from "./DataDisplay";
import SchemeDataModel from "../models/SchemeDataModel";
import { useEffect } from "react";
import { Auth, Storage } from "aws-amplify";
import MediaDataModel from "../models/MediaDataModel";
import ImportMediaDataModel from "../models/ImportDataModel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabNavigation() {
  const [value, setValue] = React.useState(0);
  const [jsonData, setJsonData] = React.useState<SchemeDataModel[]>([]);
  const [loadingData, setLoadingData] = React.useState(false);

  useEffect(() => {
    if (jsonData.length === 0) {
      setLoadingData(true);
      getUserGroups()
        .then((groups) => {
          getJsonData(groups);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingData(false));
    }
  }, []);

  const getUserGroups = async (): Promise<Set<string>> => {
    const user = await Auth.currentAuthenticatedUser();

    if (
      !user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"]
    ) {
      return new Set<string>();
    }

    const groups = Array.from(
      user.getSignInUserSession().getAccessToken().payload["cognito:groups"]
    ) as string[];

    return new Set(groups);
  };

  const getJsonData = async (groups: Set<string>) => {
    const files = await Storage.list("MediaData/");
    const relFiles = files.filter((x) => {
      for (const group of groups) {
        if (
          (x.key?.toUpperCase().includes(group.toUpperCase()) ||
            groups.has("Admin")) &&
          x.key?.includes(".json")
        ) {
          return true;
        }
      }
      return false;
    });

    const data: ImportMediaDataModel[] = [];

    for (const filePath of relFiles) {
      if (!filePath.key) continue;

      const fileRaw = await Storage.get(filePath.key, {
        level: "public",
        download: true,
      });

      const blob = fileRaw.Body as Blob;
      const text = await blob.text();

      data.push(...JSON.parse(text));
    }

    //const data: ImportMediaDataModel[] = JSON.parse(text);

    if (data.length === 0) {
      setJsonData([{ SchemeName: "NoData", MediaData: [] }]);
      return;
    }

    const schemesInData = new Set(data.map((x) => x.SchemeName));

    const schemes: SchemeDataModel[] = Array.from(schemesInData).map(
      (scheme) => {
        const schemeData = data.filter((x) => x.SchemeName === scheme);
        const mappedSchemeData = schemeData.map((x, index) => {
          const model: MediaDataModel = {
            id: index,
            videoUrl: x.MediaUrl,
            title: x.MediaTitle.replaceAll(/(&lt;[\w,/]+&gt;)|&nbsp;/g, ""),
            mediaPrimaryKeyId: x.MediaPrimaryKeyId,
            conversions: x.TotalConversions,
            impressions: x.TotalImpressions,
            relativeImpact: x.RelativeImpact,
          };

          return model;
        });

        return {
          SchemeName: scheme,
          MediaData: mappedSchemeData.filter(
            (x) => x.impressions > 0 && x.videoUrl != ""
          ),
        };
      }
    );

    setJsonData(schemes.filter((x) => x.MediaData.length > 0));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabs = jsonData.map((data, index) => {
    return <Tab label={data.SchemeName} {...a11yProps(index)} />;
  });

  const tabPanels = jsonData.map((data, index) => {
    return (
      <TabPanel value={value} index={index}>
        <DataDisplay data={data.MediaData} loading={loadingData} />
      </TabPanel>
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs}
        </Tabs>
      </Box>
      {tabPanels}
    </Box>
  );
}
