import React from "react";
import logo from "./logo.svg";

import { Routes, Route, Link, BrowserRouter } from "react-router-dom";

import { Amplify, Storage } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Home from "./Components/Home";
import { Login } from "./Components/Login";
Amplify.configure(awsconfig);

function App() {
  const { user } = useAuthenticator();

  if (user) {
    return <Home />;
  }

  return <Login />;
}

export default App;
